@use "../../Foundation/color";
@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";

@mixin orderColumns {
  $narrow: 100px;
  $wide: 160px;
  display: grid;
  grid-template-columns: $narrow $wide $wide repeat(2, $wide) repeat(3, $narrow) $wide 200px;
  > li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
}
.poc-one-box-p-orders__title {
  font-size: 24px;
  padding: variable.$gutter;
  @include screen.screen("pc-only") {
    font-size: 26px;
    margin-bottom: variable.$gutter;
    padding: 0;
  }
}
.poc-one-box-p-orders__weeklyShipmentItemSummaries {
  margin-bottom: variable.$gutter;
  padding: 0 variable.$gutter;
  @include screen.screen("pc-only") {
    display: flex;
    justify-content: space-between;
    margin-bottom: variable.$gutter;
    padding: 0;
  }
}
.poc-one-box-p-orders__header {
  padding: 0 variable.$gutter;
  @include screen.screen("pc-only") {
    padding: 0 variable.$gutter variable.$gutter;
  }
}

.poc-one-box-p-orders__table {
  position: relative;
  @include screen.screen("pc-only") {
    &::after {
      content: "";
      display: block;
      width: 45px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(to left, rgba(color.$c-bg-basic, 1), rgba(color.$c-bg-basic, 0.8), rgba(color.$c-bg-basic, 0));
    }
  }
}
.poc-one-box-p-orders__tableContainer {
  position: relative;
  overflow-x: scroll;
  padding: variable.$gutter variable.$gutter 0;
  background-color: color.$c-bg-basic;
  margin-bottom: variable.$gutter;
  @include screen.screen("pc-only") {
    padding: 0;
  }
}
.poc-one-box-p-orders__tableHeader {
  display: none;
  height: auto;
  font-size: 14px;
  font-weight: variable.$font-weight-bold;
  @include screen.screen("pc-only") {
    display: block;
    border-width: 2px;
  }
}
.poc-one-box-p-orders__tableHeaderItems {
  @include orderColumns();
  > li {
    border-bottom: 2px solid color.$c-border-default;
  }
}
.poc-one-box-p-orders__tableBody {
  font-size: 14px;
}
.poc-one-box-p-orders__list {
  > li {
    margin-bottom: variable.$gutter;
    &:not(:last-of-type) {
      @include screen.screen("pc-only") {
      }
    }
    @include screen.screen("pc-only") {
      margin-bottom: 0;
    }
  }
}
.poc-one-box-p-orders__items {
  margin-bottom: variable.$gutter;
  @include screen.screen("pc-only") {
    margin-bottom: 0;
    @include orderColumns();
  }
  > li {
    border-bottom: 1px solid color.$c-border-default;
    &:not(:last-of-type) {
      margin-bottom: 0.15em;
    }
  }
}
.poc-one-box-p-orders__dl {
  display: grid;
  grid-template-columns: 1fr 2fr;
  > dt {
    font-weight: variable.$font-weight-normal;
    &::after {
      content: ":";
      margin-right: 0.5em;
    }
    @include screen.screen("pc-only") {
      display: none;
    }
  }
  @include screen.screen("pc-only") {
    grid-template-columns: auto;
  }
  > dd {
    font-weight: variable.$font-weight-bold;
    @include screen.screen("pc-only") {
      font-weight: variable.$font-weight-normal;
    }
  }
}
