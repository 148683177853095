@use "../../Foundation/color";
@use "../../Foundation/variable";

.c-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 0.25em 0.75em;
  border-radius: 4px;
  background-color: color.$c-bg-basic;
  color: color.$c-txt-default;
  font-size: 12px;
}
