@use "../../Foundation/color";
@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "sass:math";

@mixin shipmentItemColumns {
  display: grid;
  grid-template-columns: 7em 1fr 6em 4em;
  padding: 0;
  > li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
}

.poc-one-box-p-weeklyShipmentItemSummary {
  width: 100%;
  position: relative;
  font-size: 14px;
  @include screen.screen("pc-only") {
    flex: 0 0 calc(50% - #{math.div(variable.$gutter, 2)});
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__header {
  display: block;
  height: auto;
  padding-bottom: 0;
  background-color: color.$c-bg-basic;
  @include screen.screen("pc-only") {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    left: 0;
    padding-bottom: variable.$gutter;
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__title {
  display: none;
  font-size: 16px;
  font-weight: variable.$font-weight-bold;
  margin-right: variable.$gutter * 3;
  @include screen.screen("pc-only") {
    display: block;
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__shippingDateMessage {
  font-size: 14px;
  font-weight: variable.$font-weight-bold;
  color: color.$c-txt-default;
  text-align: center;
  @include screen.screen("pc-only") {
    padding: 0;
  }
  &.is-pcOnly {
    display: none;
    @include screen.screen("pc-only") {
      display: block;
    }
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__tableHeader {
  height: auto;
  background-color: color.$c-bg-basic;
  font-weight: variable.$font-weight-bold;
  @include screen.screen("pc-only") {
    position: sticky;
    top: 40px;
    left: 0;
    border-bottom: 2px solid color.$c-border-default;
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__tableHeaderItems {
  display: none;
  @include screen.screen("pc-only") {
    @include shipmentItemColumns();
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__tableList {
  > li {
    &:not(:last-of-type) {
      border-bottom: 1px solid color.$c-border-default;
    }
  }
  @include screen.screen("pc-only") {
    overflow-y: scroll;
    max-height: 300px;
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__items {
  padding: variable.$gutter 8px;
  > li {
    &:not(:last-of-type) {
      margin-bottom: 0.15em;
    }
  }
  @include screen.screen("pc-only") {
    @include shipmentItemColumns();
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  > dt {
    font-weight: variable.$font-weight-bold;
    @include screen.screen("pc-only") {
      display: none;
    }
  }
  @include screen.screen("pc-only") {
    grid-template-columns: auto;
  }
}
.poc-one-box-p-weeklyShipmentItemSummary__noItem {
  padding: variable.$gutter 8px;
}
