/* dimen */
$dimen-sp-mini: 320;
$dimen-sp-medium: 375;
$dimen-sp: 414;
$dimen-sp-h: 599;
$dimen-tablet: 768;
$dimen-pc: 960;
$dimen-pc-large: 1240;

/* spacing */
$gutter: 1rem;

/* font */
$font-weight-normal: 400;
$font-weight-bold: 700;
