@use "../../Foundation/color";
@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";

.poc-one-box-c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 variable.$gutter;
  background-color: color.$c-bg-basic;
  border: 1px solid color.$c-border-default;
  border-radius: 6px;
  color: color.$c-txt-default;
  font-weight: variable.$font-weight-bold;
}
.poc-one-box-c-btn--disabled {
  background-color: color.$c-bg-disabled;
  color: color.$c-txt-disabled;
}
.poc-one-box-c-btn--primary {
  background-color: #369;
  border: none;
  color: color.$c-txt-inverted;
}
.poc-one-box-c-btn--pagination {
  min-width: 40px;
  height: 40px;
  background-color: color.$c-bg-basic;
  border: 1px solid color.$c-border-default;
  color: color.$c-txt-default;
  font-size: 16px;
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: color.$c-txt-default;
  }
  &.is-current {
    background-color: #369;
    border: none;
    color: color.$c-txt-inverted;
    &:link,
    &:hover,
    &:active,
    &:visited {
      color: color.$c-txt-inverted;
    }
  }
}
