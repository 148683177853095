@use "../../Foundation/color";
@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";

.poc-one-box-p-weeklyShipmentItemSummaries {
  width: 100%;
  background-color: color.$c-bg-basic;
  @include screen.screen("pc-only") {
    background-color: unset;
  }
}
.poc-one-box-p-weeklyShipmentItemSummaries__tabList {
  display: flex;
  border-bottom: 1px solid color.$c-border-default;
  > li {
    flex: 0 0 50%;
  }
  @include screen.screen("pc-only") {
    display: none;
  }
}
.poc-one-box-p-weeklyShipmentItemSummaries__tabLink {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em 0;
  color: color.$c-txt-default;
  font-weight: variable.$font-weight-bold;
  &[aria-selected="true"] {
    border-bottom: 2px solid color.$c-border-primary;
  }
}

.poc-one-box-p-weeklyShipmentItemSummaries__tabPanelList {
  > li {
    max-height: 280px;
    position: relative;
    overflow-y: scroll;
    padding: 0 variable.$gutter;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 45px;
      position: sticky;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(color.$c-bg-basic, 1), rgba(color.$c-bg-basic, 0.8), rgba(color.$c-bg-basic, 0));
    }
    &[hidden] {
      display: none;
      @include screen.screen("pc-only") {
        display: block;
      }
    }
  }
  @include screen.screen("pc-only") {
    display: flex;
    justify-content: space-between;
    > li {
      width: calc(50% - #{variable.$gutter});
      padding: variable.$gutter variable.$gutter 0;
      background-color: color.$c-bg-basic;
    }
  }
}
