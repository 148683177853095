$white: #fff;
$c-default: #363636;
$c-success: #22b24c;
$c-warning: #f5e625;
$c-danger: #f57a00;
$c-primary: #369;

$c-txt-default: $c-default;
$c-txt-inverted: $white;
$c-txt-disabled: #bbb;
$c-bg-basic: $white;
$c-bg-success: $c-success;
$c-bg-warning: $c-warning;
$c-bg-dangeer: $c-danger;
$c-bg-primary: $c-primary;
$c-bg-disabled: #ebebeb;

$c-border-default: #dbdbdb;
$c-border-primary: #369;
